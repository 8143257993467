import React, { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { 
  StyledInfos, 
  StyledInfosCol, 
  StyledInfosItem,
} from "../style";

const Infos = () => {
  const { pageLabels } = useContext(DataContext);
  const labels = pageLabels.infos;

  return (
    <StyledInfos id="infos">
      <StyledInfosCol>
        <InfosItem 
          title={labels.genre}
          value={labels.genreValue}
        />
        <InfosItem 
          title={labels.creator}
          value={labels.creatorValue}
        />
        <InfosItem 
          title={labels.screenwriter}
          value={labels.screenwriterValue}
        />
      </StyledInfosCol>
      <StyledInfosCol>
        <InfosItem 
          title={labels.history}
          value={labels.historyValue1}
        />
        <InfosItem 
          title={labels.history2}
          value={labels.historyValue2}
        />
      </StyledInfosCol>
    </StyledInfos>
  );
};

const InfosItem = ({ title, value }) => {
  return (
    <StyledInfosItem>
      <h3 dangerouslySetInnerHTML={{ __html: title }}/>
      <p dangerouslySetInnerHTML={{ __html: value }}/>
    </StyledInfosItem>
  );
};

export default Infos;
